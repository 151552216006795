import React from 'react';
import { FaceType, Direction } from 'js/components/ui/Icon/Navic/type';

export type Props = {
  faceType?: FaceType;
  direction?: Direction;
  className?: string;
  alt?: string;
};

export const NavicPresenter: React.FC<Props> = ({
  faceType = 'smile',
  direction = 'front',
  className,
  alt = '',
}) => {
  const filename = `navic_${direction}_${faceType.replace('-', '_')}.svg`;
  return (
    <img
      className={className}
      src={`/global/img/navic/${filename}`}
      alt={alt}
    />
  );
};
