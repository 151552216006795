/*-----------------------------------------------------
◇ Google Analytics
-----------------------------------------------------*/

const GA = {
  MEASUREMENT_ID: 'G-1VP5L7TBQ8',
  UA_MEASUREMENT_ID: 'UA-19301051-1',
};

// *** Google Analytics - gtag.js読み込み
if (typeof window !== 'undefined') {
  const gtagScriptTag = document.createElement('script');
  gtagScriptTag.async = true;
  gtagScriptTag.src =
    'https://www.googletagmanager.com/gtag/js?id=' + GA.MEASUREMENT_ID;
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag?.parentNode?.insertBefore(gtagScriptTag, null);

  // google公式のスニペットをそのまま使いたいので警告を無視する
  /* eslint-disable */
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  function gtag() {
    // @ts-ignore
    dataLayer.push(arguments);
  }
  // @ts-ignore
  gtag('js', new Date());
  /* eslint-enable */
  window.gtag = gtag;
}

// *** Google Analytics - PVをトラッキング
export const gaTrackPageView = (path?: string, hostname?: string) => {
  const basePath =
    path || document.location.pathname + document.location.search;
  const pathWithSlash = !basePath.startsWith('/') ? '/' + basePath : basePath;
  const pageHostname =
    hostname ||
    (document.location.hostname || 'unknown').replace(/(^www\.|\.+$)/g, '');
  const pagePath = '/' + pageHostname + pathWithSlash;
  gtag('event', 'page_view', { page_path: pagePath });
};

// *** Google Analytics - イベントをトラッキング
export const gaTrackEvent = (
  htmlElement: HTMLElement,
  category: string,
  action: string,
  label?: string,
  value?: string | number,
  noninteraction?: boolean,
) => {
  // 値の確認
  let eventPropertyValue = value;
  if (typeof value === 'string' && String(value).search(/^[0-9]+$/) != -1) {
    eventPropertyValue = Number(value);
  }
  if (typeof eventPropertyValue !== 'number') {
    eventPropertyValue = undefined;
  }

  // イベントプロパティをセット
  const eventProperty: Gtag.EventParams = { event_category: String(category) };
  if (label !== undefined) {
    eventProperty.event_label = label;
  }
  if (typeof eventPropertyValue === 'number') {
    eventProperty.value = eventPropertyValue;
  }
  if (noninteraction !== undefined) {
    eventProperty.non_interaction = Boolean(noninteraction);
  }

  // 計測
  gtag('event', String(action), eventProperty);

  return true; // 遅延処理を一時的に無効
};

// *** Google Analytics - setメソッドのラッパー
export const gaSet = (
  field: string,
  value: Gtag.CustomParams | boolean | string,
) => {
  gtag('set', field, value);
};

// 計測用関数のグローバル関数化
if (typeof window !== 'undefined') {
  window.gaTrackPageView = gaTrackPageView;
  window.gaTrackEvent = gaTrackEvent;
  window.gaSet = gaSet;
}

// *** Google Analytics - 初期化
if (typeof window !== 'undefined') {
  // トラッカーを作成
  gtag('config', GA.MEASUREMENT_ID, { send_page_view: false });
  gtag('config', GA.UA_MEASUREMENT_ID, { send_page_view: false });

  // カスタムディメンションにログインステータスを追加
  const nicknameMatch = document.cookie.match(/(^|;) *NICKNAME=([^;]*)(;| *$)/);
  const nickname = nicknameMatch ? nicknameMatch[2].replace(/ /g, '') : '';
  const isUserLoginMatch = document.cookie.match(
    /(^|;) *IS_USER_LOGIN=1(;| *$)/,
  );
  const loginStatus = nickname || isUserLoginMatch ? 'login' : 'logout';
  gaSet('dimension1', loginStatus);
  gaSet('login_status', { status: loginStatus });

  // カスタムディメンションにアンケートアプリ種別を追加
  const ua = navigator.userAgent;
  if (ua.indexOf('ECNAVIAPPS_IOS') !== -1) {
    gaSet('dimension2', 'iOS');
    gaSet('enquete_app', { device: 'ios' });
  } else if (ua.indexOf('ECNAVI_APP') !== -1) {
    gaSet('dimension2', 'Android');
    gaSet('enquete_app', { device: 'android' });
  }

  // 計測が有効であればトラッキングを行う
  const metas = document.getElementsByTagName('meta');
  let trackFlag = window === window.parent;
  for (let i = metas.length - 1; i >= 0; i--) {
    if (metas[i].name.toLowerCase() === 'ga_track_pageview') {
      const content = metas[i].content.toLowerCase();
      if (content !== 'true' && content !== 'false') {
        continue;
      } else {
        trackFlag = content === 'true';
      }
      break;
    }
  }
  if (trackFlag) {
    gaTrackPageView();
  }
}
