import React from 'react';
import classNames from 'classnames';

export type ButtonSize = 'small' | 'medium' | 'large' | 'x-large' | 'xx-large';
export type ButtonWidth =
  | 'fit'
  | 10
  | 20
  | 30
  | 40
  | 50
  | 60
  | 70
  | 80
  | 90
  | 100
  | 110
  | 120
  | 130
  | 140
  | 150
  | 160
  | 170
  | 180
  | 190
  | 200
  | 210
  | 220
  | 230
  | 240
  | 250
  | 260
  | 270
  | 280
  | 290
  | 300
  | 310
  | 320
  | 330
  | 340
  | 350
  | 360
  | 370
  | 380
  | 390
  | 400;
export type ButtonType = 'submit' | 'reset' | 'button';
export type SizeType = 'absolute' | 'relative';

export type Props = {
  className?: string;
  href?: string;
  target?: string;
  rel?: string;
  isPrimary?: boolean;
  size?: ButtonSize;
  sizeType?: SizeType;
  width?: ButtonWidth;
  isInline?: boolean;
  isHoverDisabled?: boolean;
  isDisabled?: boolean;
  type?: ButtonType;
  children?: React.ReactNode;
  callback?: (e: React.MouseEvent<HTMLElement>) => void;
  dataTestid?: string;
};

export const ButtonPresenter: React.FC<Props> = ({
  className,
  href,
  target,
  rel,
  isPrimary,
  size = 'medium',
  sizeType = 'absolute',
  width,
  isInline,
  isHoverDisabled,
  isDisabled,
  type = 'button',
  children,
  callback = () => null,
  dataTestid,
}) => {
  const props = {
    className: classNames(
      className,
      'c_button',
      { c_red: isPrimary },
      { [`c_${size.replace('-', '_')}`]: sizeType === 'absolute' },
      { [`u_size-${size}`]: sizeType === 'relative' },
      { [`c_w${width}`]: Boolean(width) && width !== 'fit' },
      { 'c_width-fit': width === 'fit' },
      { 'is-inline': isInline },
      { 'is-hover-disabled': isHoverDisabled },
    ),
  };
  return href ? (
    <a
      {...props}
      href={href}
      target={target}
      rel={rel}
      onClick={callback}
      data-testid={dataTestid}
    >
      {children}
    </a>
  ) : (
    <button
      {...props}
      onClick={callback}
      type={type}
      disabled={isDisabled}
      data-testid={dataTestid}
    >
      {children}
    </button>
  );
};
