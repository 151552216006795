import React from 'react';
import Head from 'next/head';
import { Error as ErrorComponent } from 'js/components/common/layout/Error';
import { useMessageStatusCode } from 'js/components/common/layout/Error/hooks/useMessageStatusCode';
import {
  HTTP_STATUS_CODE,
  HTTP_STATUS_ERROR_MESSAGE_MAP,
} from 'js/components/common/layout/Error/const';
import { isBrowser } from 'js/utils/isBrowser';
import 'css/global/parts.scss';

type Props = {
  statusCode?: number;
};

const Error: React.FC<Props> = ({
  statusCode = HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR,
}) => {
  const messageStatusCode = useMessageStatusCode(statusCode);

  /**
   * SSR, SSG 時には <Error /> をレンダリングさせない
   * 一律でクライアントサイドで <Error /> をレンダリングする
   * 現状のクライアントサイドに寄せたエラー設計では、SSG 時にエラーページを生成するミスが起こる可能性がある
   * 一時的なロジックなので、全体が SSR or SSG のみの構成 (エラーハンドリングを SSR に寄せる) になった時に削除する
   * Ref: https://github.com/voyagegroup/ecnavi/pull/15646#issuecomment-1467774875
   */
  if (!isBrowser()) {
    return null;
  }

  return (
    <>
      <Head>
        <title>
          {`ECナビ - ${HTTP_STATUS_ERROR_MESSAGE_MAP[messageStatusCode].heading}`}
        </title>
        <meta name="robots" content="noindex,follow,noarchive" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
        />
      </Head>
      <ErrorComponent statusCode={messageStatusCode} />
    </>
  );
};

export default Error;
