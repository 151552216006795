import React from 'react';
import {
  NavicMessagePresenter,
  Props as PresenterProps,
} from 'js/components/common/NavicMessage/presenter';

export type Props = PresenterProps;

export const NavicMessage: React.FC<Props> = (props) => (
  <NavicMessagePresenter {...props} />
);
