import { useMemo } from 'react';
import { HTTP_STATUS_CODE } from 'js/components/common/layout/Error/const';
import { StatusCode } from 'js/components/common/layout/Error/type';

export const useMessageStatusCode = (statusCode: number) => {
  return useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Object.values(HTTP_STATUS_CODE).includes(statusCode)) {
      return statusCode as StatusCode;
    }
    if (400 <= statusCode && statusCode <= 499) {
      return HTTP_STATUS_CODE.BAD_REQUEST;
    }
    return HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR;
  }, [statusCode]);
};
