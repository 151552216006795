import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { BaseSyntheticEvent } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

interface SlideWindowState {
  isShow: boolean;
}

const initialState: SlideWindowState = {
  isShow: false,
};

const refreshDisabledClass = 'pull-to-refresh-is-disabled';

export const createSlideWindowSlice = (
  name: string,
  elementId: string,
): Slice<SlideWindowState> =>
  createSlice({
    name,
    initialState,
    reducers: {
      showWindow: (
        state,
        action: PayloadAction<Event | BaseSyntheticEvent>,
      ) => {
        const windowElement = document.getElementById(elementId);
        document.body.classList.add(refreshDisabledClass);
        const bslOptions = { allowTouchMove: () => true }; // for ios
        if (windowElement) {
          disableBodyScroll(windowElement, bslOptions);
        }
        state.isShow = true;
        action.payload.stopPropagation();
      },
      hideWindow: (
        state,
        action: PayloadAction<Event | BaseSyntheticEvent>,
      ) => {
        const windowElement = document.getElementById(elementId);
        document.body.classList.remove(refreshDisabledClass);
        if (windowElement) {
          enableBodyScroll(windowElement);
        }
        state.isShow = false;
        action.payload.stopPropagation();
      },
    },
  });
