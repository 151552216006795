import { createSlideWindowSlice } from 'js/utils/createSlideWindowSlice';

export const searchWindowSlice = createSlideWindowSlice(
  'searchWindow',
  'm-global-layout-search-window-root',
);

export const { showWindow: showSearchWindow, hideWindow: hideSearchWindow } =
  searchWindowSlice.actions;
export default searchWindowSlice.reducer;
