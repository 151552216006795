import React from 'react';
import { Button, Props as ButtonProps } from 'js/components/ui/Button/Button';
import { Navic } from 'js/components/ui/Icon/Navic';
import { FaceType as NavicFaceType } from 'js/components/ui/Icon/Navic/type';

export type Props = {
  navicFaceType: NavicFaceType;
  heading: string;
  message?: React.ReactNode;
  buttons?: Pick<ButtonProps, 'children' | 'href' | 'callback' | 'isPrimary'>[];
};

export const NavicMessagePresenter: React.FC<Props> = ({
  navicFaceType,
  heading,
  message,
  buttons,
}) => (
  <section className="p_navic-message">
    <div className="p_navic-message__wrapper">
      <Navic className="p_navic-message__face" faceType={navicFaceType} />
      <div className="p_navic-message__text-wrapper is-pre-line">
        <h1 className="p_navic-message__heading">{heading}</h1>
        <p className="p_navic-message__message c_simple_notation c_underline-block">
          {message}
        </p>
      </div>
    </div>
    {buttons && (
      <div className="p_navic-message__button-wrapper">
        {buttons.map((button) => (
          <Button
            className="p_navic-message__button"
            key={String(button.children)}
            href={button.href}
            callback={button.callback}
            isPrimary={button.isPrimary}
            width="fit"
            isInline={true}
          >
            {button.children}
          </Button>
        ))}
      </div>
    )}
  </section>
);
