export type NodeEnv = 'development' | 'production' | 'test';
export type ProductEnv = 'dev' | 'staging' | 'rc' | 'qa' | 'prod';
export type PersonalEnv = string;
export type CommonBuild = 'true' | undefined;
export type CurrentCommitHash = string;
export type ApiMocking = 'disabled' | 'enabled';

export const ENVIRONMENTS = {
  NODE_ENV: process.env.NODE_ENV,
  PRODUCT_ENV: process.env.PRODUCT_ENV,
  PERSONAL_ENV: process.env.PERSONAL_ENV,
  COMMON_BUILD: !!process.env.COMMON_BUILD,
  CURRENT_COMMIT_HASH: process.env.CURRENT_COMMIT_HASH,
  API_MOCKING: process.env.NEXT_PUBLIC_API_MOCKING,
} as const;
