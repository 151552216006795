/* eslint-disable @typescript-eslint/ban-ts-comment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
// @ts-ignore
if (typeof window !== 'undefined') {
  (function (w, d, s, l, i) {
    // @ts-ignore
    w[l] = w[l] || [];
    // @ts-ignore
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    // @ts-ignore
    j.async = true;
    // @ts-ignore
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    // @ts-ignore
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-MK2NPMR5');
}
