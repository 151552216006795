import { configureStore } from '@reduxjs/toolkit';
import dailyContentsWindowReducer from 'js/store/dailyContentsWindow/dailyContentsWindowSlice';
import etcContentsWindowReducer from 'js/store/etcContentsWindow/etcContentsWindowSlice';
import favoriteWindowReducer from 'js/store/favoriteWindow/favoriteWindowSlice';
import myMenuWindowReducer from 'js/store/myMenuWindow/myMenuWindowSlice';
import researchWindowReducer from 'js/store/researchWindow/researchWindowSlice';
import searchWindowReducer from 'js/store/searchWindow/searchWindowSlice';

export const store = configureStore({
  reducer: {
    dailyContentsWindow: dailyContentsWindowReducer,
    etcContentsWindow: etcContentsWindowReducer,
    favoriteWindow: favoriteWindowReducer,
    myMenuWindow: myMenuWindowReducer,
    researchWindow: researchWindowReducer,
    searchWindow: searchWindowReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
