import { createSlideWindowSlice } from 'js/utils/createSlideWindowSlice';

export const researchWindowSlice = createSlideWindowSlice(
  'researchWindow',
  'm-global-layout-research-window-root',
);

export const {
  showWindow: showResearchWindow,
  hideWindow: hideResearchWindow,
} = researchWindowSlice.actions;
export default researchWindowSlice.reducer;
