import React from 'react';
import { NavicMessage } from 'js/components/common/NavicMessage';
import { useRouter } from 'next/router';
import { useMessageStatusCode } from 'js/components/common/layout/Error/hooks/useMessageStatusCode';
import { HTTP_STATUS_ERROR_MESSAGE_MAP } from 'js/components/common/layout/Error/const';

export type Props = {
  statusCode: number;
};

export const ErrorPresenter: React.FC<Props> = ({ statusCode }) => {
  const messageStatusCode = useMessageStatusCode(statusCode);
  const messageMap = HTTP_STATUS_ERROR_MESSAGE_MAP[messageStatusCode];
  const router = useRouter();
  return (
    <div className="p_error">
      <p className="p_error__code">
        {messageStatusCode} {messageMap.status}
      </p>
      <NavicMessage
        heading={messageMap.heading}
        message={messageMap.message}
        navicFaceType="sad"
        buttons={[
          {
            children: 'ECナビ トップ',
            href: '/',
          },
          {
            children: '前のページに戻る',
            callback: () => router.back(),
          },
        ]}
      />
    </div>
  );
};
