import { HttpStatusErrorMessageMap } from 'js/components/common/layout/Error/type';

export const HTTP_STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503,
} as const;

export const HTTP_STATUS_ERROR_MESSAGE_MAP: HttpStatusErrorMessageMap = {
  [HTTP_STATUS_CODE.BAD_REQUEST]: {
    status: 'Bad Request',
    heading: '不正なリクエストです',
    message: 'URLが間違っている可能性があります。',
  },
  [HTTP_STATUS_CODE.UNAUTHORIZED]: {
    status: 'Unauthorized',
    heading: '認証に失敗しました',
    message: '認証に必要な情報が間違っています。\n認証情報を見直してください。',
  },
  [HTTP_STATUS_CODE.FORBIDDEN]: {
    status: 'Forbidden',
    heading: 'アクセス権限がありません',
    message: 'このページにアクセスする権限がありません。',
  },
  [HTTP_STATUS_CODE.NOT_FOUND]: {
    status: 'Not Found',
    heading: 'ページが見つかりません',
    message:
      'アクセスしたページは削除されたか、\nURLが変更されている可能性があります。',
  },
  [HTTP_STATUS_CODE.UNPROCESSABLE_ENTITY]: {
    status: 'Unprocessable Entity',
    heading: '正常に処理できません',
    message:
      'しばらくしてもページが表示できない場合は\nお問い合わせにご連絡ください。',
  },
  [HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR]: {
    status: 'Internal Server Error',
    heading: '内部サーバーエラー',
    message:
      'サーバーのエラーにより表示できません。\nしばらく時間をおいてからお試しください。',
  },
  [HTTP_STATUS_CODE.SERVICE_UNAVAILABLE]: {
    status: 'Service Unavailable',
    heading: 'アクセスが集中しています',
    message:
      'アクセス増加により表示できません。\nしばらく時間をおいてからお試しください。',
  },
} as const;
