import { NextSeoProps } from 'next-seo';

export const SEO: NextSeoProps = {
  titleTemplate: '%s - ECナビ',
  defaultTitle: 'ECナビ',
  additionalMetaTags: [
    {
      name: 'format-detection',
      content: 'telephone=no',
    },
  ],
  additionalLinkTags: [
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
    },
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
  ],
};
