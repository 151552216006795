import { createSlideWindowSlice } from 'js/utils/createSlideWindowSlice';

export const etcContentsWindowSlice = createSlideWindowSlice(
  'etcContentsWindow',
  'm-global-layout-etc-contents-window-root',
);

export const {
  showWindow: showEtcContentsWindow,
  hideWindow: hideEtcContentsWindow,
} = etcContentsWindowSlice.actions;
export default etcContentsWindowSlice.reducer;
