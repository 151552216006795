import { createSlideWindowSlice } from 'js/utils/createSlideWindowSlice';

export const favoriteWindowSlice = createSlideWindowSlice(
  'favoriteWindow',
  'm-global-layout-favorite-window-root',
);

export const {
  showWindow: showFavoriteWindow,
  hideWindow: hideFavoriteWindow,
} = favoriteWindowSlice.actions;
export default favoriteWindowSlice.reducer;
