import { createSlideWindowSlice } from 'js/utils/createSlideWindowSlice';

export const dailyContentsWindowSlice = createSlideWindowSlice(
  'dailyContentsWindow',
  'm-global-layout-daily-contents-window-root',
);

export const {
  showWindow: showDailyContentsWindow,
  hideWindow: hideDailyContentsWindow,
} = dailyContentsWindowSlice.actions;
export default dailyContentsWindowSlice.reducer;
