import { datadogLogs } from '@datadog/browser-logs';

/**
 * 環境変数は通常js/const/env/env.tsからENVIRONMENTSをimportして使う
 * しかしdatadog.ts内でそれをしてしまうと未定義の環境変数が存在する場合にprocess is not definedエラーが発生してしまい、
 * datadogLogsが動作せずブラウザ上で発生するあらゆるエラーを検知することが出来なくなってしまう
 * そのためdatadog.ts内だけは直接process.envから環境変数を読み取るようにしている
 */
datadogLogs.init({
  // see https://app.datadoghq.com/organization-settings/client-tokens
  // ECNAVI_CLIENT_TOKEN
  clientToken: 'pub7a1d55aa3c464df075ffa06287863ae3',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 10,
  env: process.env.PRODUCT_ENV,
  service: 'ecnavi',
  version: process.env.CURRENT_COMMIT_HASH,
});

datadogLogs.createLogger('criticalErrorLogger', { level: 'error' });
datadogLogs.createLogger('criticalErrorLoggerForWebpack', { level: 'error' });
