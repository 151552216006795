import { createSlideWindowSlice } from 'js/utils/createSlideWindowSlice';

export const myMenuWindowSlice = createSlideWindowSlice(
  'myMenuWindow',
  'm-global-layout-my-menu-window-root',
);

export const { showWindow: showMyMenuWindow, hideWindow: hideMyMenuWindow } =
  myMenuWindowSlice.actions;
export default myMenuWindowSlice.reducer;
