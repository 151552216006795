import React from 'react';
import {
  ButtonPresenter,
  Props as PresenterProps,
} from 'js/components/ui/Button/Button/presenter';

export type Props = PresenterProps;

export const Button: React.FC<Props> = ({
  className,
  href,
  target,
  rel,
  isPrimary,
  size,
  sizeType,
  width,
  isInline,
  isHoverDisabled,
  isDisabled,
  type,
  children,
  callback,
  dataTestid,
}) => (
  <ButtonPresenter
    className={className}
    href={href}
    target={target}
    rel={rel}
    isPrimary={isPrimary}
    size={size}
    sizeType={sizeType}
    width={width}
    isInline={isInline}
    isHoverDisabled={isHoverDisabled}
    isDisabled={isDisabled}
    type={type}
    callback={callback}
    dataTestid={dataTestid}
  >
    {children}
  </ButtonPresenter>
);
